.profile-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f2f5f9ff;
}
.profile-image {
  flex: 0 0 auto;
  width: 100%;
  height: 500px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80");
}
.profile-image1 {
  left: auto;
  right: auto;
  width: 100%;
  bottom: -1px;
  z-index: 100;
  position: absolute;
  object-fit: cover;
}
.profile-bg {
  top: auto;
  flex: 0 0 auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  height: 100%;
  display: flex;
  opacity: 0.5;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-black);
}
.profile-container01 {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  z-index: 100;
  max-width: 1320px;
  box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04);
  margin-top: -12rem;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.profile-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-around;
}
.profile-container03 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.profile-image2 {
  top: -60%;
  left: 0px;
  right: 0px;
  width: 150px;
  bottom: auto;
  margin: auto;
  position: absolute;
  box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.profile-container04 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.profile-container05 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: center;
}
.profile-container06 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.profile-text {
  color: var(--dl-color-secondary-300);
}
.profile-container07 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.profile-icon {
  fill: var(--dl-color-secondary-500);
  width: 18px;
  height: 18px;
}
.profile-text1 {
  color: var(--dl-color-secondary-500);
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.profile-container08 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.profile-icon2 {
  fill: var(--dl-color-secondary-500);
  width: 18px;
  height: 18px;
}
.profile-text2 {
  color: var(--dl-color-secondary-500);
  margin-left: var(--dl-space-space-halfunit);
}
.profile-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.profile-container10 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.profile-icon4 {
  fill: var(--dl-color-secondary-500);
  width: 18px;
  height: 18px;
}
.profile-text3 {
  color: var(--dl-color-secondary-500);
  margin-left: var(--dl-space-space-halfunit);
}
.profile-container11 {
  flex: 0 0 auto;
  width: 90%;
  height: 0.5px;
  display: flex;
  opacity: 0.5;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.profile-text4 {
  width: 75%;
  font-size: 1.125rem;
  text-align: center;
  font-weight: 300;
  line-height: 1.625;
  margin-bottom: var(--dl-space-space-unit);
}
.profile-text5 {
  color: var(--dl-color-secondary-300);
  display: block;
  text-align: left;
}
.profile-text6 {
  color: var(--dl-color-pimary-500);
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
}
.profile-text6:hover {
  color: var(--dl-color-pimary-300);
}
@media(max-width: 991px) {
  .profile-container02 {
    flex-direction: column;
  }
  .profile-container03 {
    margin-top: var(--dl-space-space-doubleunit);
    margin-bottom: var(--dl-space-space-doubleunit);
  }
}
