
.card {
    box-shadow: 0px 0px 23px -5px rgba(0, 0, 0, 0.22);
    border-radius: 15px;
  }
  
  
  .card-img {
    width: 100%;
    max-height: 374px;
    object-fit: cover;
  }
  
  .rating {
    display: flex;
    color: #000000;
    justify-content: space-between;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.3em;
    background-color: #F5F5F5;
  }
  
  .card-content {
    padding: 20px;
  }
  
  .card-title {
    color: #051A2D;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3em;
    margin-bottom: 8px;
  }
  
  .card-subtitle {
    color: #21282F;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  
  .card-action {
    font-size: 18px;
    font-weight: 500;
    fill: #FFFFFF;
    color: #FFFFFF;
    background-color: transparent;
    background-image: linear-gradient(130deg, #4aa4e3ff 21%, #4aa4e3ff 100%);
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0px 7px 20px -1px rgba(0, 0, 0, 0.31);
    padding: 17px 0px 17px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .card-action:hover {
    background-color: transparent;
    background-image: linear-gradient(130deg, #0A467D 21%, #1F4262 100%);
  }
  
  
  
  
  