.header-header {
  flex: 0 0 auto;
  width: 100%;
  height: 75px;
  display: flex;
  padding: var(--dl-space-space-unitandhalf);
  z-index: 1;
  max-width: 1320px;
  align-items: center;
  margin-bottom: 0.75rem;
  flex-direction: row;
  justify-content: space-between;
}
.header-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-navlink {
  display: contents;
}
.header-heading {
  color: var(--dl-color-gray-white);
  font-weight: 700;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-navlink1 {
  display: contents;
}
.header-container1 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
  text-decoration: none;
}
.header-navlink2 {
  display: contents;
}
.header-container2 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: row;
  text-decoration: none;
}
.header-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-icon {
  width: 24px;
  height: 24px;
}
.header-container3 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header-icon02 {
  fill: var(--dl-color-gray-900);
  width: 24px;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-icon04 {
  fill: var(--dl-color-gray-900);
  width: 24px;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-unitandhalf);
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.header-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.header-navlink3 {
  display: contents;
}
.header-heading1 {
  color: var(--dl-color-secondary-400);
  font-weight: 700;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-close-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-icon06 {
  width: 24px;
  height: 24px;
}
.header-mid {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.header-navlink4 {
  display: contents;
}
.header-container4 {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-direction: row;
  text-decoration: none;
}
.header-icon08 {
  fill: var(--dl-color-secondary-400);
  width: 13px;
}
.header-text {
  color: var(--dl-color-secondary-400);
  margin-left: var(--dl-space-space-halfunit);
}
.header-navlink5 {
  display: contents;
}
.header-container5 {
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}
.header-icon10 {
  fill: var(--dl-color-secondary-400);
  width: 13px;
}
.header-text1 {
  color: var(--dl-color-secondary-400);
  margin-left: var(--dl-space-space-halfunit);
}
.header-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  align-items: center;
  flex-direction: row;
}
.header-icon15 {
  fill: var(--dl-color-secondary-400);
  width: 24px;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-icon17 {
  fill: var(--dl-color-secondary-400);
  width: 24px;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-icon19 {
  fill: var(--dl-color-secondary-400);
  width: 24px;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-root-class-name {
  margin-bottom: 0px;
}
@media(max-width: 991px) {
  .header-header {
    max-width: 1320px;
    margin-bottom: var(--dl-space-space-triplequarter);
  }
  .header-icon02 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .header-icon04 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .header-icon15 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .header-icon17 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .header-icon19 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
@media(max-width: 767px) {
  .header-header {
    background-color: var(--dl-color-gray-white);
  }
  .header-heading {
    color: var(--dl-color-secondary-400);
  }
  .header-container1 {
    display: none;
  }
  .header-container2 {
    display: none;
  }
  .header-burger-menu {
    display: flex;
  }
  .header-icon {
    fill: var(--dl-color-secondary-400);
  }
  .header-container3 {
    display: none;
  }
  .header-root-class-name {
    margin-bottom: 0px;
  }
}

.course-icon {
    fill-rule:evenodd;
    clip-rule:evenodd;
}

.header-icon-twitter {
    width: 24px; /* Example width */
    height: 24px; /* Example height */
    fill: white; /* Example fill color. Match other header icons. */
    cursor: pointer; /* Make it clear it's clickable */
    margin: 4px; /* Add padding for spacing */
}
